export const GET_DEPARTMENT_REQUEST = "GET_DEPARTMENT_REQUEST";
export const GET_DEPARTMENT_SUCCESS = "GET_DEPARTMENT_SUCCESS";
export const GET_DEPARTMENT_FAILURE = "GET_DEPARTMENT_FAILURE";

export const ADD_DEPARTMENT_REQUEST = "ADD_DEPARTMENT_REQUEST";
export const ADD_DEPARTMENT_SUCCESS = "ADD_DEPARTMENT_SUCCESS";
export const ADD_DEPARTMENT_FAILURE = "ADD_DEPARTMENT_FAILURE";

export const EDIT_DEPARTMENT_REQUEST = "EDIT_DEPARTMENT_REQUEST";
export const EDIT_DEPARTMENT_SUCCESS = "EDIT_DEPARTMENT_SUCCESS";
export const EDIT_DEPARTMENT_FAILURE = "EDIT_DEPARTMENT_FAILURE";

export const DELETE_DEPARTMENT_REQUEST = "DELETE_DEPARTMENT_REQUEST";
export const DELETE_DEPARTMENT_SUCCESS = "DELETE_DEPARTMENT_SUCCESS";
export const DELETE_DEPARTMENT_FAILURE = "DELETE_DEPARTMENT_FAILURE";

export const GET_TEAM_REQUEST = "GET_TEAM_REQUEST";
export const GET_TEAM_SUCCESS = "GET_TEAM_SUCCESS";
export const GET_TEAM_FAILURE = "GET_TEAM_FAILURE";

export const ADD_TEAM_REQUEST = "ADD_TEAM_REQUEST";
export const ADD_TEAM_SUCCESS = "ADD_TEAM_SUCCESS";
export const ADD_TEAM_FAILURE = "ADD_TEAM_FAILURE";

export const EDIT_TEAM_REQUEST = "EDIT_TEAM_REQUEST";
export const EDIT_TEAM_SUCCESS = "EDIT_TEAM_SUCCESS";
export const EDIT_TEAM_FAILURE = "EDIT_TEAM_FAILURE";

export const DELETE_TEAM_REQUEST = "DELETE_TEAM_REQUEST";
export const DELETE_TEAM_SUCCESS = "DELETE_TEAM_SUCCESS";
export const DELETE_TEAM_FAILURE = "DELETE_TEAM_FAILURE";

export const GET_TEAM_MEMBER_REQUEST = "GET_TEAM_MEMBER_REQUEST";
export const GET_TEAM_MEMBER_SUCCESS = "GET_TEAM_MEMBER_SUCCESS";
export const GET_TEAM_MEMBER_FAILURE = "GET_TEAM_MEMBER_FAILURE";

export const ADD_TEAM_MEMBER_REQUEST = "ADD_TEAM_MEMBER_REQUEST";
export const ADD_TEAM_MEMBER_SUCCESS = "ADD_TEAM_MEMBER_SUCCESS";
export const ADD_TEAM_MEMBER_FAILURE = "ADD_TEAM_MEMBER_FAILURE";

export const EDIT_TEAM_MEMBER_REQUEST = "EDIT_TEAM_MEMBER_REQUEST";
export const EDIT_TEAM_MEMBER_SUCCESS = "EDIT_TEAM_MEMBER_SUCCESS";
export const EDIT_TEAM_MEMBER_FAILURE = "EDIT_TEAM_MEMBER_FAILURE";

export const DELETE_TEAM_MEMBER_REQUEST = "DELETE_TEAM_MEMBER_REQUEST";
export const DELETE_TEAM_MEMBER_SUCCESS = "DELETE_TEAM_MEMBER_SUCCESS";
export const DELETE_TEAM_MEMBER_FAILURE = "DELETE_TEAM_MEMBER_FAILURE";

export const DISABLE_TEAM_MEMBER_REQUEST = "DISABLE_TEAM_MEMBER_REQUEST";
export const DISABLE_TEAM_MEMBER_SUCCESS = "DISABLE_TEAM_MEMBER_SUCCESS";
export const DISABLE_TEAM_MEMBER_FAILURE = "DISABLE_TEAM_MEMBER_FAILURE";

export const ENABLE_TEAM_MEMBER_REQUEST = "ENABLE_TEAM_MEMBER_REQUEST";
export const ENABLE_TEAM_MEMBER_SUCCESS = "ENABLE_TEAM_MEMBER_SUCCESS";
export const ENABLE_TEAM_MEMBER_FAILURE = "ENABLE_TEAM_MEMBER_FAILURE";

export const GET_MEMBER_LOGS_REQUEST = "GET_MEMBER_LOGS_REQUEST";
export const GET_MEMBER_LOGS_SUCCESS = "GET_MEMBER_LOGS_SUCCESS";
export const GET_MEMBER_LOGS_FAILURE = "GET_MEMBER_LOGS_FAILURE";

export const SEND_INVITATION_REQUEST = "SEND_INVITATION_REQUEST";
export const SEND_INVITATION_SUCCESS = "SEND_INVITATION_SUCCESS";
export const SEND_INVITATION_FAILURE = "SEND_INVITATION_FAILURE";

export const DELETE_MANY_REQUEST = "DELETE_MANY_REQUEST";
export const DELETE_MANY_SUCCESS = "DELETE_MANY_SUCCESS";
export const DELETE_MANY_FAILURE = "DELETE_MANY_FAILURE";

export const MODIFY_STATUS_REQUEST = "MODIFY_STATUS_REQUEST";
export const MODIFY_STATUS_SUCCESS = "MODIFY_STATUS_SUCCESS";
export const MODIFY_STATUS_FAILURE = "MODIFY_STATUS_FAILURE";

export const UNFREEZE_MEMBER_REQUEST = "UNFREEZE_MEMBER_REQUEST";
export const UNFREEZE_MEMBER_SUCCESS = "UNFREEZE_MEMBER_SUCCESS";
export const UNFREEZE_MEMBER_FAILURE = "UNFREEZE_MEMBER_FAILURE";
