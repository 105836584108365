import React, { lazy } from "react";
import { Routes, Route, Brow } from "react-router-dom";
import Loader from "../components/Loader/Loader";

const Signup = lazy(() => import("../screens/Auth/onBoarding/index"));

const AuthRoutes = () => {
  return (
    <React.Suspense fallback={<Loader />}>
      <Routes>
        <Route path="/*" element={<Signup />} />
      </Routes>
    </React.Suspense>
  );
};

export default AuthRoutes;
