import axios from "../axiosConfig";

class Routes {
  login(data) {
    return axios.post("/auth/login", data);
  }
  loginRequest(data) {
    return axios.post("/auth/login-request", data);
  }
  passwordRequest(data) {
    return axios.post("/auth/password-request", data);
  }
  signup(data) {
    return axios.post("/auth/signup", data);
  }
  updateUser(data) {
    return axios.post(`/auth/update-user`, data);
  }
  createAccount(data) {
    return axios.patch("/auth/create-account", data);
  }
  sendTokenToNumber(data) {
    return axios.post("/auth/send-phone-token", data);
  }
  phoneVerification(data) {
    return axios.post("/auth/phone-verification", data);
  }
  uploadS3(data) {
    return axios.post(`/auth/upload-s3`, data);
  }
  addWorkstream(id, data) {
    return axios.post(`/workStream/add-workstream/${id}`, data);
  }
  editWorkstream(id, data) {
    return axios.patch(`/workStream/edit-workstream/${id}`, data);
  }
  signUpWithGoogle(data) {
    return axios.post("/auth/signup-with-google", data);
  }
  resetPassword(data) {
    return axios.patch(`/auth/resetPassword`, data);
  }
  forgetPassword(data) {
    return axios.post(`/auth/forgetPassword`, data);
  }
  emailVerification(data) {
    return axios.patch(`/auth/emailVerification`, data);
  }
  resendVerification(userId) {
    return axios.patch(`/auth/resendVerification/${userId}`);
  }
  getMagicLink(data) {
    return axios.post("/auth/get-magiclink", data);
  }
  makeMagicLink(data) {
    return axios.post("/auth/make-magiclink", data);
  }
}

export default new Routes();
