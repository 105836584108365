import React, { useEffect, useState } from "react";
import { Form, Input, Button, Layout, Typography, Avatar, Result } from "antd";
import {
  LockOutlined,
  MailOutlined,
  PhoneOutlined,
  UnlockOutlined,
  UserOutlined,
} from "@ant-design/icons";

import { Row, Col, Spin, Select } from "antd";

import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { AuthAPI } from "../../api";
import { loginUser } from "../../redux/auth/actionCreator";

const { Title } = Typography;

const MagicLogin = () => {
  const dispatch = useDispatch();

  const [memberLoading, setMemberLoading] = useState(true);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [state, setState] = useState();
  const [isActive, setActive] = React.useState(false);
  const [expire, setExpire] = useState(false);

  const { email, key } = useParams();

  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const email = params.get("email");
    const key = params.get("key");

    console.log("Email:", email);
    console.log("Key:", key);
    if (email && key) {
      getMagicLink(email, key);
    }
  }, [location]);

  const getMagicLink = async (email, key) => {
    let res = await AuthAPI.getMagicLink({ email, key });
    debugger;
    if (res.status == 200 && res?.data?.userId) {
      setMemberLoading(false);
      setExpire(false);
      dispatch(loginUser({ email: res?.data?.email, magicLink: true }));
    } else {
      setExpire(true);
      setMemberLoading(false);
    }
  };

  return (
    <>
      {memberLoading ? (
        <div
          style={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spin />
        </div>
      ) : (
        <div>
          {expire == true ? (
            <div
              style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Result
                status="403"
                title="Link Expired"
                subTitle="Sorry, Login Link has been expired"
              />
            </div>
          ) : (
            <Layout className="signup-main"></Layout>
          )}
        </div>
      )}
    </>
  );
};

export default MagicLogin;
