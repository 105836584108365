export const GET_USER_REQUEST = "GET_USER_REQUEST";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAILURE = "GET_USER_FAILURE";

export const GET_LOGIN_REQUEST = "GET_LOGIN_REQUEST";
export const GET_LOGIN_SUCCESS = "GET_LOGIN_SUCCESS";
export const GET_LOGIN_FAILURE = "GET_LOGIN_FAILURE";

export const SIGNUP_REQUEST = "SIGNUP_REQUEST";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAILURE = "SIGNUP_FAILURE";

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

export const CODE_MODAL_REQUEST = "CODE_MODAL_REQUEST";
export const CODE_MODAL_SUCCESS = "CODE_MODAL_SUCCESS";
export const CODE_MODAL_FAILURE = "CODE_MODAL_FAILURE";

export const EMAIL_VERIFY_REQUEST = "EMAIL_VERIFY_REQUEST";
export const EMAIL_VERIFY_SUCCESS = "EMAIL_VERIFY_SUCCESS";
export const EMAIL_VERIFY_FAILURE = "EMAIL_VERIFY_FAILURE";

export const RESEND_VERIFY_REQUEST = "RESEND_VERIFY_REQUEST";
export const RESEND_VERIFY_SUCCESS = "RESEND_VERIFY_SUCCESS";
export const RESEND_VERIFY_FAILURE = "RESEND_VERIFY_FAILURE";

export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";

export const FORGET_PASSWORD_REQUEST = "FORGET_PASSWORD_REQUEST";
export const FORGET_PASSWORD_SUCCESS = "FORGET_PASSWORD_SUCCESS";
export const FORGET_PASSWORD_FAILURE = "FORGET_PASSWORD_FAILURE";

export const GOOGLE_AUTH_REQUEST = "GOOGLE_AUTH_REQUEST";
export const GOOGLE_AUTH_SUCCESS = "GOOGLE_AUTH_SUCCESS";
export const GOOGLE_AUTH_FAILURE = "GOOGLE_AUTH_FAILURE";

export const STATUS_REQUEST = "STATUS_REQUEST";
export const STATUS_SUCCESS = "STATUS_SUCCESS";

export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";

export const BOARDING_SIGNUP_REQUEST = "BOARDING_SIGNUP_REQUEST";
export const BOARDING_SIGNUP_SUCCESS = "BOARDING_SIGNUP_SUCCESS";
export const BOARDING_SIGNUP_FAILURE = "BOARDING_SIGNUP_FAILURE";

export const SINGLE_MEMBER_REQUEST = "SINGLE_MEMBER_REQUEST";
export const SINGLE_MEMBER_SUCCESS = "SINGLE_MEMBER_SUCCESS";
export const SINGLE_MEMBER_FAILURE = "SINGLE_MEMBER_FAILURE";

export const CONTACT_US_REQUEST = "CONTACT_US_REQUEST";
export const CONTACT_US_SUCCESS = "CONTACT_US_SUCCESS";
export const CONTACT_US_FAILURE = "CONTACT_US_FAILURE";

export const ADD_CUSTOMER_CARD_REQUEST = "ADD_CUSTOMER_CARD_REQUEST";
export const ADD_CUSTOMER_CARD_SUCCESS = "ADD_CUSTOMER_CARD_SUCCESS";
export const ADD_CUSTOMER_CARD_FAILURE = "ADD_CUSTOMER_CARD_FAILURE";

export const MODIFY_CONFETTI_REQUEST = "MODIFY_CONFETTI_REQUEST";
export const MODIFY_CONFETTI_SUCCESS = "MODIFY_CONFETTI_SUCCESS";
export const MODIFY_CONFETTI_FAILURE = "MODIFY_CONFETTI_FAILURE";

export const MODIFY_FIRST_LOGIN_REQUEST = "MODIFY_FIRST_LOGIN_REQUEST";
export const MODIFY_FIRST_LOGIN_SUCCESS = "MODIFY_FIRST_LOGIN_SUCCESS";
export const MODIFY_FIRST_LOGIN_FAILURE = "MODIFY_FIRST_LOGIN_FAILURE";

export const ENABLE_LOGIN_REQUEST = "ENABLE_LOGIN_REQUEST";
export const ENABLE_LOGIN_SUCCESS = "ENABLE_LOGIN_SUCCESS";
export const ENABLE_LOGIN_FAILURE = "ENABLE_LOGIN_FAILURE";
