import axios from "../axiosConfig";

class Routes {
  addActivity(id, data) {
    return axios.post(`/activity/add-activity/${id}`, data);
  }
  editActivity(id, data) {
    return axios.patch(`/activity/edit-activity/${id}`, data);
  }
  pmo_edit_Activity(id, data) {
    return axios.patch(`/pmo/edit-activity/${id}`, data);
  }
  my_activities(id) {
    return axios.get(`/activity/my-activities/${id}`);
  }
}

export default new Routes();
