import * as types from "./types";

const initState = {
  loading: false,
  configLoading: false,
  configuration: null,
  roles: [],
  success: false,
  members: [],
  allMembers: [],
  invoices: [],
};

const adminReducer = (state = initState, action) => {
  switch (action.type) {
    //get configuration
    case types.GET_CONFIGURATION_REQUEST:
      debugger;
      return {
        ...state,
        configLoading: true,
        success: false,
      };
    case types.GET_CONFIGURATION_SUCCESS:
      return {
        ...state,
        configLoading: false,
        success: true,
        configuration: action.payload ? action.payload : null,
      };
    case types.GET_CONFIGURATION_FAILURE:
      return {
        ...state,
        configLoading: true,
        success: false,
      };

    //post configuration
    case types.POST_CONFIGURATION_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.POST_CONFIGURATION_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        configuration: action.payload.data,
      };
    case types.POST_CONFIGURATION_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //get roles
    case types.GET_ROLE_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.GET_ROLE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        roles: action.payload,
      };
    case types.GET_ROLE_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //add role
    case types.ADD_ROLE_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.ADD_ROLE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        roles: [...state.roles, action.payload.data],
      };
    case types.ADD_ROLE_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //edit role
    case types.EDIT_ROLE_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.EDIT_ROLE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        roles: state.roles?.map((item) =>
          item._id == action.payload.data?._id ? action.payload.data : item
        ),
      };
    case types.POST_CONFIGURATION_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //delete role
    case types.DELETE_ROLE_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.DELETE_ROLE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        roles: state.roles?.filter((item) =>
          item?._id !== action.payload.data._id ? item : null
        ),
      };
    case types.DELETE_ROLE_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //get members by role
    case types.ROLE_MEMBERS_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.ROLE_MEMBERS_SUCCESS:
      let array = [];
      action.payload?.map((item) => {
        let result = state?.members?.find((elem) => elem?._id == item?._id);
        if (!result) {
          array?.push(item);
        }
      });
      return {
        ...state,
        loading: false,
        success: true,
        members: [...state.members, ...array],
      };
    case types.DELETE_ROLE_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //get members
    case types.GET_MEMBERS_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.GET_MEMBERS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        allMembers: action.payload,
      };
    case types.GET_MEMBERS_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //get members by role
    case types.FILTER_MEMBERS_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.FILTER_MEMBERS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        members: state?.members?.filter(
          (elem) => elem?.systemRole?._id !== action.payload?.id
        ),
      };
    case types.DELETE_ROLE_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //clear config
    case types.CLEAR_CONFIG_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.CLEAR_CONFIG_SUCCESS:
      return {
        ...state,
        loading: false,
        configuration: null,
        roles: [],
        success: false,
        members: [],
      };
    case types.CLEAR_CONFIG_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //get invoices
    case types.GET_INVOICES_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.GET_INVOICES_SUCCESS:
      return {
        ...state,
        loading: false,
        success: false,
        invoices: action.payload.reverse(),
      };
    case types.GET_INVOICES_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //create usage
    case types.CREATE_USAGE_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.CREATE_USAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: false,
      };
    case types.CREATE_USAGE_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    default:
      return state;
  }
};

export default adminReducer;
