/* eslint-disable */
import { put, call, takeLatest, select } from "redux-saga/effects";
import * as api from "./service";
import * as types from "./types";

//get activities
function* getActivitiesSaga(action) {
  try {
    const result = yield call(api.getActivities, action.payload);
    yield put({
      type: types.GET_ACTIVITIES_SUCCESS,
      payload: result?.data,
    });
  } catch (error) {
    yield put({
      type: types.GET_ACTIVITIES_FAILURE,
      payload: error,
    });
  }
}

//add activity
function* addActivitySaga(action) {
  try {
    const result = yield call(api.addActivity, action.payload);
    yield put({
      type: types.POST_ACTIVITY_SUCCESS,
      payload: result?.data,
    });
  } catch (error) {
    yield put({
      type: types.POST_ACTIVITY_FAILURE,
      payload: error,
    });
  }
}

//convert type
function* convertTypeSaga(action) {
  try {
    const result = yield call(api.convertType, action.payload);
    yield put({
      type: types.CONVERT_TYPE_SUCCESS,
      payload: result?.data?.data,
    });
  } catch (error) {
    yield put({
      type: types.CONVERT_TYPE_FAILURE,
      payload: error,
    });
  }
}

//delete activity
function* deleteActivitySaga(action) {
  try {
    const result = yield call(api.deleteActivity, action.payload);
    yield put({
      type: types.DELETE_ACTIVITY_SUCCESS,
      payload: result?.data?.data,
    });
  } catch (error) {
    yield put({
      type: types.DELETE_ACTIVITY_FAILURE,
      payload: error,
    });
  }
}

//edit activity
function* editActivitySaga(action) {
  try {
    const result = yield call(api.editActivity, action.payload);
    yield put({
      type: types.EDIT_ACTIVITY_SUCCESS,
      payload: result?.data,
    });
  } catch (error) {
    yield put({
      type: types.EDIT_ACTIVITY_FAILURE,
      payload: error,
    });
  }
}

//add log
function* addLogSaga(action) {
  try {
    const result = yield call(api.addLog, action.payload);
    yield put({
      type: types.ADD_LOG_SUCCESS,
      payload: result?.data,
    });
  } catch (error) {
    yield put({
      type: types.ADD_LOG_FAILURE,
      payload: error,
    });
  }
}

//get logs
function* getLogsSaga(action) {
  try {
    const result = yield call(api.getLogs, action.payload);
    yield put({
      type: types.GET_LOG_SUCCESS,
      payload: result?.data,
    });
  } catch (error) {
    yield put({
      type: types.GET_LOG_FAILURE,
      payload: error,
    });
  }
}

//filter activities
function* filterActivities(action) {
  try {
    yield put({
      type: types.FILTER_ACTIVITY_SUCCESS,
      payload: action.payload,
    });
  } catch (error) {
    yield put({
      type: types.FILTER_ACTIVITY_FAILURE,
      payload: error,
    });
  }
}

//clear table
function* clearTable() {
  try {
    yield put({
      type: types.CLEAR_TABLE_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: types.CLEAR_TABLE_FAILURE,
      payload: error,
    });
  }
}

//clear table
function* get_ActivityClient(action) {
  try {
    const result = yield call(api.getActivityClient, action.payload);
    yield put({
      type: types.GET_CLIENT_ACT_SUCCESS,
      payload: result.data,
    });
  } catch (error) {
    yield put({
      type: types.GET_CLIENT_ACT_FAILURE,
      payload: error,
    });
  }
}

//upload-media
function* uploadMedia(action) {
  try {
    const result = yield call(api.uploadMedia, action.payload);
    yield put({
      type: types.UPLOAD_MEDIA_SUCCESS,
      payload: result.data,
    });
  } catch (error) {
    yield put({
      type: types.UPLOAD_MEDIA_FAILURE,
      payload: error,
    });
  }
}

//delete-media
function* deleteMedia(action) {
  try {
    const result = yield call(api.deleteMedia, action.payload);
    yield put({
      type: types.DELETE_MEDIA_SUCCESS,
      payload: result.data,
    });
  } catch (error) {
    yield put({
      type: types.DELETE_MEDIA_FAILURE,
      payload: error,
    });
  }
}

//my-activities
function* myActivities(action) {
  try {
    const result = yield call(api.myActivities, action.payload);
    yield put({
      type: types.MY_ACTIVITIES_SUCCESS,
      payload: result.data,
    });
  } catch (error) {
    yield put({
      type: types.MY_ACTIVITIES_FAILURE,
      payload: error,
    });
  }
}

//get-media
function* getMedia(action) {
  try {
    const result = yield call(api.getMedia, action.payload);
    yield put({
      type: types.GET_MEDIA_SUCCESS,
      payload: result.data,
    });
  } catch (error) {
    yield put({
      type: types.GET_MEDIA_FAILURE,
      payload: error,
    });
  }
}

//get-media
function* getMediaById(action) {
  try {
    const result = yield call(api.getMediaById, action.payload);
    //;
    yield put({
      type: types.GET_MEDIA_BYID_SUCCESS,
      payload: result.data,
    });
  } catch (error) {
    yield put({
      type: types.GET_MEDIA_BYID_FAILURE,
      payload: error,
    });
  }
}

//get-media
function* setCardData(action) {
  try {
    yield put({
      type: types.SET_CARD_DATA_SUCCESS,
      payload: action.payload,
    });
  } catch (error) {
    yield put({
      type: types.SET_CARD_DATA_FAILURE,
      payload: error,
    });
  }
}

//get archived activities
function* getArchivedActivities(action) {
  try {
    const result = yield call(api.getArchivedActivities, action.payload);
    yield put({
      type: types.GET_ARCHIVE_ACTIVITIES_SUCCESS,
      payload: result.data,
    });
  } catch (error) {
    yield put({
      type: types.GET_ARCHIVE_ACTIVITIES_FAILURE,
      payload: error,
    });
  }
}

//clear table
function* get_Activities_by_workstreams(action) {
  try {
    const result = yield call(api.getActivitiesByWorkstreams, action.payload);
    yield put({
      type: types.GET_ACTIVITIES_BY_WORKSTREAMS_SUCCESS,
      payload: result.data,
    });
  } catch (error) {
    yield put({
      type: types.GET_ACTIVITIES_BY_WORKSTREAMS_FAILURE,
      payload: error,
    });
  }
}

//get activities by team member
function* get_Activities_by_member(action) {
  try {
    const result = yield call(api.getActivityMember, action.payload);
    yield put({
      type: types.GET_MEMBER_ACT_SUCCESS,
      payload: result.data,
    });
  } catch (error) {
    yield put({
      type: types.GET_MEMBER_ACT_FAILURE,
      payload: error,
    });
  }
}

//swp activities
function* swap_Activities(action) {
  try {
    const result = yield call(api.swapActivities, action.payload);
    yield put({
      type: types.SWAP_ACTIVITIES_SUCCESS,
      payload: result.data,
    });
  } catch (error) {
    yield put({
      type: types.SWAP_ACTIVITIES_FAILURE,
      payload: error,
    });
  }
}

//my activities
function* clear_my_activities(action) {
  try {
    yield put({
      type: types.CLEAR_MY_ACTIVITIES_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: types.CLEAR_MY_ACTIVITIES_FAILURE,
      payload: error,
    });
  }
}

//is-my activities
function* isMyActivities() {
  try {
    yield put({
      type: types.IS_MY_ACTIVITIES_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: types.IS_MY_ACTIVITIES_FAILURE,
      payload: error,
    });
  }
}

//edit activities
function* editActivityState(action) {
  try {
    yield put({
      type: types.EDIT_ACTIVITY_STATE_SUCCESS,
      payload: action.payload,
    });
  } catch (error) {
    yield put({
      type: types.EDIT_ACTIVITY_STATE_FAILURE,
      payload: error,
    });
  }
}

//get logs by workstreams
function* getLogsWorkstreams(action) {
  try {
    const result = yield call(api.getLogsByWorkstreams, action.payload);
    yield put({
      type: types.GET_WORKSTREAM_LOG_SUCCESS,
      payload: result.data,
    });
  } catch (error) {
    yield put({
      type: types.GET_WORKSTREAM_LOG_FAILURE,
      payload: error,
    });
  }
}

//get logs by member
function* getLogsMember(action) {
  try {
    const result = yield call(api.getLogsByMember, action.payload);
    yield put({
      type: types.GET_MEMBER_LOG_SUCCESS,
      payload: result.data,
    });
  } catch (error) {
    yield put({
      type: types.GET_MEMBER_LOG_FAILURE,
      payload: error,
    });
  }
}

//clear logs
function* clearWorkstreamLogs() {
  try {
    yield put({
      type: types.CLEAR_LOG_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: types.CLEAR_LOG_FAILURE,
      payload: error,
    });
  }
}

//add dependency
function* dependencyTable(action) {
  try {
    yield put({
      type: types.ADD_DEPENDENCY_SUCCESS,
      payload: action.payload,
    });
  } catch (error) {
    yield put({
      type: types.ADD_DEPENDENCY_FAILURE,
      payload: error,
    });
  }
}

//edit all activity
function* editAllActivity(action) {
  try {
    const result = yield call(api.editAllActivity, action.payload);
    yield put({
      type: types.EDIT_ALL_ACTIVITY_SUCCESS,
      payload: result.data,
    });
  } catch (error) {
    yield put({
      type: types.EDIT_ALL_ACTIVITY_FAILURE,
      payload: error,
    });
  }
}

function* modifyDeliverableSaga(action) {
  try {
    yield put({
      type: types.MODIFY_DELIVERABLE_SUCCESS,
      payload: action.payload,
    });
  } catch (error) {
    yield put({
      type: types.MODIFY_DELIVERABLE_FAILURE,
      payload: error,
    });
  }
}

export function* activitiesWatcher() {
  yield takeLatest(types.GET_ACTIVITIES_REQUEST, getActivitiesSaga);
  yield takeLatest(types.GET_WORKSTREAM_LOG_REQUEST, getLogsWorkstreams);
  yield takeLatest(types.EDIT_ACTIVITY_STATE_REQUEST, editActivityState);
  yield takeLatest(types.POST_ACTIVITY_REQUEST, addActivitySaga);
  yield takeLatest(types.CONVERT_TYPE_REQUEST, convertTypeSaga);
  yield takeLatest(types.DELETE_ACTIVITY_REQUEST, deleteActivitySaga);
  yield takeLatest(types.EDIT_ACTIVITY_REQUEST, editActivitySaga);
  yield takeLatest(types.ADD_LOG_REQUEST, addLogSaga);
  yield takeLatest(types.GET_LOG_REQUEST, getLogsSaga);
  yield takeLatest(types.FILTER_ACTIVITY_REQUEST, filterActivities);
  yield takeLatest(types.CLEAR_TABLE_REQUEST, clearTable);
  yield takeLatest(types.GET_CLIENT_ACT_REQUEST, get_ActivityClient);
  yield takeLatest(types.UPLOAD_MEDIA_REQUEST, uploadMedia);
  yield takeLatest(types.MY_ACTIVITIES_REQUEST, myActivities);
  yield takeLatest(types.CLEAR_MY_ACTIVITIES_REQUEST, clear_my_activities);
  yield takeLatest(types.GET_MEDIA_REQUEST, getMedia);
  yield takeLatest(types.SET_CARD_DATA_REQUEST, setCardData);
  yield takeLatest(types.GET_MEDIA_BYID_REQUEST, getMediaById);
  yield takeLatest(types.GET_ARCHIVE_ACTIVITIES_REQUEST, getArchivedActivities);
  yield takeLatest(types.DELETE_MEDIA_REQUEST, deleteMedia);
  yield takeLatest(types.GET_MEMBER_ACT_REQUEST, get_Activities_by_member);
  yield takeLatest(types.SWAP_ACTIVITIES_REQUEST, swap_Activities);
  yield takeLatest(types.IS_MY_ACTIVITIES_REQUEST, isMyActivities);
  yield takeLatest(types.CLEAR_LOG_REQUEST, clearWorkstreamLogs);
  yield takeLatest(types.ADD_DEPENDENCY_REQUEST, dependencyTable);
  yield takeLatest(types.GET_MEMBER_LOG_REQUEST, getLogsMember);
  yield takeLatest(types.MODIFY_DELIVERABLE_REQUEST, modifyDeliverableSaga);
  yield takeLatest(
    types.GET_ACTIVITIES_BY_WORKSTREAMS_REQUEST,
    get_Activities_by_workstreams
  );
  yield takeLatest(types.EDIT_ALL_ACTIVITY_REQUEST, editAllActivity);
}
