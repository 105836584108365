import Router from "./routes/index";
// import "antd/dist/antd.css";
import "antd/dist/antd.variable.min.css";

import { ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "./App.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { getUser } from "./redux/auth/actionCreator";
import { getTeamMember } from "./redux/auth/actionCreator";

import AOS from "aos";
import "aos/dist/aos.css";

import { ConfigProvider, notification } from "antd";
import StripeModal from "./components/stripe/index";
import { getConfiguration } from "./redux/admin/actionCreator";
import {
  activeUsers,
  editConversation,
  getConversations,
  makeSockets,
} from "./redux/socket/actionCreator";
import { io } from "socket.io-client";
import { TbNumber0Small } from "react-icons/tb";
import { useIdle } from "react-use";
import { editAllActivity } from "./redux/activities/actionCreator";
import { clearActivityIdle } from "./redux/ActivityIdle/actionCreator";

AOS.init({
  offset: 200,
  duration: 900,
  once: true,
});

// import { ToastContainer, Slide } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import { useSelector } from 'react-redux'
function App() {
  const dispatch = useDispatch();
  const { user, login, paymentId } = useSelector((state) => state.auth);
  const { configuration } = useSelector((state) => state.admin);
  const [isModalOpen, setModalOpen] = useState(false);
  const [arrivalMsg, setArrivalMsg] = useState(null);
  const [arrivalMsgGroup, setArrivalMsgGroup] = useState(null);
  const [notify, setNotify] = useState(false);
  const [read, setRead] = useState(null);
  const { allActiveUsers, conversations } = useSelector(
    (state) => state.socket
  );
  const superAdmin = useSelector(
    (state) => state?.admin?.configuration?.adminId
  );
  const { totalMembers } = useSelector((state) => state.teams);
  // const { data, logs } = useSelector((state) => state.activityIdle);
  const socket = useRef();

  // const isIdle = useIdle(4000);
  // const handleIdle = () => {
  //   dispatch(editAllActivity({ data, logs }));
  //   dispatch(clearActivityIdle());
  // };

  // useEffect(() => {
  //   if (isIdle) {
  //     handleIdle();
  //   }
  // }, [isIdle]);

  useEffect(() => {
    if (user && user?.role !== "admin") {
      dispatch(getTeamMember(user?._id));
      dispatch(getConfiguration(user?.adminId));
      dispatch(getConversations(user?._id));
      dispatch(getUser(user._id));
    } else if (user) {
      dispatch(getUser(user._id));
      dispatch(getConfiguration(user?._id));
      dispatch(getConversations(user?._id));
    }
  }, []);

  useEffect(() => {
    if (
      configuration?.adminId?.trailEnd &&
      !configuration?.adminId?.paymentMethodId
    ) {
      let currentDate = new Date().toLocaleDateString();
      let endTrail = configuration?.adminId?.trailEnd;
      if (
        currentDate == endTrail ||
        new Date(currentDate) > new Date(endTrail)
      ) {
        setModalOpen(true);
      }
    } else {
      setModalOpen(false);
    }
    if (user && user?.paymentMethodId) {
      setModalOpen(false);
    }
  }, [configuration, user]);

  useEffect(() => {
    ConfigProvider.config({
      theme: {
        primaryColor: configuration?.primaryColor
          ? configuration?.primaryColor
          : "#32cd32",
      },
    });
  }, [configuration]);

  useEffect(() => {
    socket.current = io(`${process.env.REACT_APP_SOCKET_URL}`);
    // socket.current = io(`wss://apis.naplozz.hu`);
    socket?.current?.emit("addUser", user?._id);
    socket?.current?.on("getUsers", (users) => {
      dispatch(activeUsers(users));
    });
    socket?.current?.on("getMessage", (data) => {
      console.log("Message received:", data);
      if (data) {
        setArrivalMsg({
          text: data?.text,
          messageId: data?.messageId,
          senderId: data?.senderId,
          receiverId: data?.receiverId,
          createdAt: new Date(),
          c_id: data?.c_id,
          left: false,
          link: data?.link ? data?.link : null,
          msgType: data?.msgType,
        });
      }
    });
    socket?.current?.on("getGroupMessage", (data) => {
      console.log(data, "group message");
      if (data) {
        setArrivalMsgGroup({
          text: data?.text,
          senderId: data?.senderId,
          createdAt: new Date(),
          c_id: data?.c_id,
          left: false,
          link: data?.link ? data?.link : null,
          msgType: data?.msgType,
          messageId: data?.messageId,
        });
      }
    });
    socket?.current?.on("read", (data) => {
      if (data) {
        setRead(data);
      }
    });
    dispatch(makeSockets(socket));
  }, [user?._id]);

  useEffect(() => {
    if (
      arrivalMsg &&
      !window.location.href.includes("chat") &&
      user?._id == arrivalMsg?.receiverId
    ) {
      dispatch(getConversations(user?._id));
      const parser = new DOMParser();
      const doc = parser.parseFromString(arrivalMsg?.text, "text/html");
      let sender = allActiveUsers?.find(
        (user) => user?.userId == arrivalMsg?.senderId
      );
      console.log(sender, arrivalMsg);
      sender = [...totalMembers, superAdmin]?.find(
        (el) => el?._id == sender?.userId
      );
      if (user?.status !== "DNB") {
        notification.open({
          message: sender?.firstName + " " + sender?.lastName,
          description: doc.body.textContent,
          onClick: () => window.open("/chat", "_blank"),
        });
      }
    }
  }, [arrivalMsg]);

  useEffect(() => {
    if (arrivalMsgGroup && !window.location.href.includes("chat")) {
      let conversation = conversations.find(
        (el) => el?._id == arrivalMsgGroup.c_id
      );
      if (conversation) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(arrivalMsgGroup?.text, "text/html");
        let sender = allActiveUsers?.find(
          (user) => user?.userId == arrivalMsgGroup?.senderId
        );
        sender = [...totalMembers, superAdmin]?.find(
          (el) => el?._id == sender?.userId
        );
        setNotify(true);
        if (
          user?.status !== "DNB" &&
          conversation?.notifications?.includes(user?._id) &&
          user?._id !== arrivalMsgGroup?.senderId
        ) {
          notification.open({
            message:
              conversation?.groupName +
              " : " +
              " " +
              sender?.firstName +
              " " +
              sender?.lastName,
            description: doc.body.textContent,
            onClick: () => window.open("/chat", "_blank"),
          });
        }
      }
    }
  }, [arrivalMsgGroup]);

  useEffect(() => {
    if (notify) {
      dispatch(getConversations(user?._id));
      setNotify(false);
    }
  }, [notify]);

  useEffect(() => {
    if (read && read?.userId == user?._id) {
      dispatch(editConversation(read?.cid));
      setRead(null);
    }
  }, [read]);

  return (
    <>
      <Router />
      <StripeModal
        isModalOpen={isModalOpen}
        setModalOpen={setModalOpen}
        role={user?.role}
        closeable={false}
        title={"Enter Billing Information"}
      />
      <ToastContainer
        position="top-right"
        autoClose={6000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        transition={Slide}
      />
    </>
  );
}

export default App;
