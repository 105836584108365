import * as types from "./types";

export const getUser = (data) => {
  return {
    type: types.GET_USER_REQUEST,
    payload: data,
  };
};

export const modifyConfetti = (id, data) => {
  return {
    type: types.MODIFY_CONFETTI_REQUEST,
    payload: { id, data },
  };
};

export const modifyFirstLogin = (id, data) => {
  return {
    type: types.MODIFY_FIRST_LOGIN_REQUEST,
    payload: { id, data },
  };
};

export const updateUser = (id, data) => {
  return {
    type: types.UPDATE_USER_REQUEST,
    payload: { id, data },
  };
};

export const loginUser = (data) => {
  return {
    type: types.GET_LOGIN_REQUEST,
    payload: data,
  };
};

export const signup = (data) => {
  // //;
  return {
    type: types.SIGNUP_REQUEST,
    payload: data,
  };
};

export const googleAuth = (data) => {
  return {
    type: types.GOOGLE_AUTH_REQUEST,
    payload: data,
  };
};

export const logoutUser = () => {
  return {
    type: types.LOGOUT_REQUEST,
  };
};

export const closeCodeModal = () => {
  return {
    type: types.CODE_MODAL_REQUEST,
  };
};

export const emailVerification = (data) => {
  return {
    type: types.EMAIL_VERIFY_REQUEST,
    payload: data,
  };
};

export const resendVerification = (data) => {
  return {
    type: types.RESEND_VERIFY_REQUEST,
    payload: data,
  };
};

export const resetPassword = (data) => {
  return {
    type: types.RESET_PASSWORD_REQUEST,
    payload: data,
  };
};

export const forgetPassword = (data) => {
  return {
    type: types.FORGET_PASSWORD_REQUEST,
    payload: data,
  };
};

export const successRequest = () => {
  return {
    type: types.STATUS_REQUEST,
  };
};

export const boardingSignup = (id, data) => {
  return {
    type: types.BOARDING_SIGNUP_REQUEST,
    payload: { id, data },
  };
};

export const getTeamMember = (data) => {
  return {
    type: types.SINGLE_MEMBER_REQUEST,
    payload: data,
  };
};

export const contactus = (data) => {
  return {
    type: types.CONTACT_US_REQUEST,
    payload: data,
  };
};

export const addCustomerCard = (data) => {
  return {
    type: types.ADD_CUSTOMER_CARD_REQUEST,
    payload: data,
  };
};

export const enableLoginRequest = (id, data) => {
  return {
    type: types.ENABLE_LOGIN_REQUEST,
    payload: { id, data },
  };
};
