/* eslint-disable */
import axios from "../../api/axiosConfig";

//get user
export const getUser = (data) => {
  return axios.get(`/user/getUser/${data}`);
};

//modify
export const modifyConfetti = (data) => {
  return axios.get(`/user/confetti/${data.id}/${data.data}`);
};

//first login
export const modifyFirstLogin = (data) => {
  return axios.get(`/user/first_login/${data.id}/${data.data}`);
};

//login user
export const loginUser = (data) => {
  return axios.post(`/auth/login`, data);
};

//signup user
export const signUp = (data) => {
  return axios.post(`/auth/signup`, data);
};

//email verify
export const emailVerification = (data) => {
  return axios.patch(`/auth/emailVerification`, data);
};

//resend verify
export const resendVerification = (userId) => {
  return axios.patch(`/auth/resendVerification/${userId}`);
};

//reset password
export const resetPassword = (data) => {
  return axios.patch(`/auth/resetPassword`, data);
};

//forget password
export const forgetPassword = (data) => {
  return axios.post(`/auth/forgetPassword`, data);
};

//google auth request
export const googleAuth = (data) => {
  return axios.post(`/auth/signup-with-google`, data);
};

//update user
export const updateUser = (data) => {
  return axios.patch(`/user/updateUser/${data?.id}`, data.data);
};

//boarding signup
export const boardingSignup = (data) => {
  return axios.patch(`/auth/boarding-signup/${data?.id}`, data.data);
};

//get member
export const getMember = (id) => {
  return axios.get(`/team/get-team-member/${id}`);
};

//contact-us
export const contactUs = (data) => {
  return axios.post(`/auth/contact-us`, data);
};

//add-custmer-card
export const addCustomerCard = (data) => {
  return axios.patch(`/stripe/add-customer-card`, data);
};

//enable-login-request
export const enableLoginRequest = (data) => {
  return axios.post(`/security/handle-security/${data.id}`, data.data);
};
